import api from "@/api";
import { TableRow } from "@/models/type";
import { ElMessageBox, ElNotification } from "element-plus";
import { Watch, Vue } from "vue-property-decorator";
import './index.scss'

export default class ShopCard extends Vue {
  public tableData: Array<any> = []
  public ids: Array<number> = []
  public rowList: Array<any> = []
  public toId = 0
  public pushNum = 0
  public mountedNum = 0

  public checked = false
  public clientWidth = 0

  public tableList: Array<TableRow> = [
    {label: '商品类型', width: '400px',  slot: {
      default: (scope: any): JSX.Element => <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={() => this.goDetail(scope.row.productId)}
      >
        {scope.row.productPic && <img
          src={scope.row.productPic}
          style={{
            width: '50px',
            marginRight: '10px'
          }}
          alt=""
        />}
        <span>{scope.row.productName}</span>
      </div>
    }},
    {label: '单价', slot: {
      default: (scope: any): JSX.Element => <span>{scope.row.price}</span>
    }},
    {label: '数量', slot: {
      default: (scope: any): JSX.Element => <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <span
          class={[
            'el-icon-remove',
            scope.row.quantity === 1 ? 'noDrop' : 'pointer'
          ]}
          style={{
            fontSize: '24px',
            marginRight: '10px',
            color: scope.row.quantity === 1 ? '#ccc' : ''
          }}
          onClick={() => this.remove(scope.row)}
        ></span>
        <span>{scope.row.quantity}</span>
        <span
          class={[
            'el-icon-circle-plus',
            scope.row.quantity === scope.row.realStock ? 'noDrop' : 'pointer'
          ]}
          style={{
            fontSize: '24px',
            marginLeft: '10px',
            color: scope.row.quantity === scope.row.realStock ? '#ccc' : ''
          }}
          onClick={() => this.plus(scope.row)}
        />
      </div>
    }},
    {label: '金额', slot: {
      default: (scope: any): JSX.Element => <span>{scope.row.price * scope.row.quantity}</span>
    }},
    {label: '操作', slot: {
      default: (scope: any): JSX.Element => <span
        class="pointer"
        style={{
          color: '#F56C6C',
          fontWeight: 500
        }}
        onClick={() => this.deleteCard(scope.row.id)}
      >
        删除
      </span>
    }},
  ]

  deleteCard(id?: number): void {
    const ids: Array<number> = id ? [id] : this.ids
    if(!(ids.length)) {
      ElNotification({
        type: 'warning',
        message: '请选择要删除的购物车商品'
      })
      return
    }
    ElMessageBox.confirm('确定删除该商品？', '删除购物车商品', {
      type: 'warning'
    }).then(() => {
      api.cartDelete(ids).then(res => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除商品成功'
        })
        this.mountedNum++
      })
    }).catch(() => false)
  }

  remove(row: any): void {
    if(row.quantity === 1) {
      return
    }
    api.cartUpdateQuantity({
      id: row.id,
      quantity: --row.quantity
    })
  }

  plus(row: any): void {
    if(row.quantity === row.realStock) {
      return
    }
    api.cartUpdateQuantity({
      id: row.id,
      quantity: ++row.quantity
    })
  }

  @Watch('pushNum')
  pushNumPush(num: number): void {
    if(num) {
      this.$router.push({
        name: 'SanchuangDetail',
        params: { id: this.toId }
      })
    }
  }

  @Watch('mountedNum')
  mountedNumChange(num: number): void {
    if(num) {
      this.getList()
    }
  }

  goDetail(id: number): void {
    if(this.clientWidth <= 900) {
      // TODO 跳转的mobile
      window.location.href = `http://mobile.taosseract.com/pages/mall/detail?id=${id}`
    } else {
      this.toId = id
      this.pushNum++
    }
  }

  onSelectionChange(row: any[]): void {
    this.rowList = row
    this.checked = this.rowList.length === this.tableData.length
    this.ids = row.map(item => item.id)
  }

  cardTable(): JSX.Element {
    if(this.clientWidth <= 900) {
      return <div class="m-shop-content">
        {this.tableData.map(item => <div
          style={{
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '10px'
            }}
          >
            <el-checkbox v-model={item.checked} onChange={(e: Event) => this.oneCheckedClick(item, e)} />
            <img src={item.productPic} alt="" style={{ width: '74px', marginLeft: '10px' }} />
            <div
              style={{
                flex: '1',
                paddingLeft: '15px'
              }}
            >
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{
                  fontSize: '14px',
                  color: '#302928'
                }}>{item.productName}</div>
                <span style={{
                  fontSize: '13px',
                  color: '#302928'
                }}>单价：{item.price}</span>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                <span style="color: #878787">数量：</span>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <span
                    class={[
                      'el-icon-remove',
                      item.quantity === 1 ? 'noDrop' : 'pointer'
                    ]}
                    style={{
                      fontSize: '24px',
                      marginRight: '10px',
                      color: item.quantity === 1 ? '#ccc' : ''
                    }}
                    onClick={() => this.remove(item)}
                  ></span>
                  <span>{item.quantity}</span>
                  <span
                    class={[
                      'el-icon-circle-plus',
                      item.quantity === item.realStock ? 'noDrop' : 'pointer'
                    ]}
                    style={{
                      fontSize: '24px',
                      marginLeft: '10px',
                      color: item.quantity === item.realStock ? '#ccc' : ''
                    }}
                    onClick={() => this.plus(item)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </div>
    } else {
      return <el-table
        ref="table"
        data={this.tableData}
        width="100%"
        class="shop_card-table"
        align="center"
        onSelectionChange={this.onSelectionChange}
      >
        <el-table-column type="selection" align="center" />
        {this.tableList.map(item => <el-table-column
          label={item.label}
          prop={item.prop}
          v-slots={item.slot}
          width={item.width}
          align="center"
        />)}
      </el-table>
    }
  }

  oneCheckedClick(item: any, e: Event): void {
    if(e) {
      this.ids.push(item.id)
      this.rowList.push(item)
    } else {
      const index = this.ids.findIndex(i => i === item.id)
      this.ids.splice(index, 1)
      this.rowList.splice(index, 1)
    }
    this.checked = this.ids.length === this.tableData.length
  }

  getList(): void {
    api.cartList().then(res => {
      if(this.clientWidth <= 900) {
        res.data.forEach((item: any) => {
          item.checked = false
        })
      }
      this.tableData = res.data
    })
  }

  get allPrice(): number {
    return this.rowList.reduce((preV, curV) => preV + curV.quantity * curV.price, 0)
  }

  checkedClick(e: Event): void {
    if(this.clientWidth <= 900) {
      this.tableData.forEach(item => {
        item.checked = e
        this.ids = e ? this.tableData.map(item => item.id) : []
        this.rowList = e ? this.tableData : []
      })
    } else {
      const __table = this.$refs.table as any
      if(e) {
        this.tableData.forEach(item => {
          __table.toggleRowSelection(item)
        })
      } else {
        __table.clearSelection();
      }
    }
  }

  setDom(): JSX.Element {
    return <div class="shop_card-bottom">
      <div>
        <el-checkbox v-model={this.checked} onChange={this.checkedClick}>全选</el-checkbox>
        <span
          class="pointer"
          style={{
            color: '#F56C6C',
            fontWeight: 500,
            marginLeft: '10px'
          }}
          onClick={() => this.deleteCard(0)}
        >
          删除
        </span>
      </div>
      <div class="shop_card-right">
        <div>已选商品: <span>{this.ids.length}</span> 件</div>
        <div>实付款: HK$ <span>{this.allPrice}</span></div>
        <div class={['btn', this.ids.length ? 'pointer' : 'noDrop']} onClick={this.toPay}>付款</div>
      </div>
    </div>
  }

  toPay(): void {
    if(!(this.ids.length)) {
      return
    }
    this.$router.push({
      name: 'SanchuangPay',
      query: {
        ids: this.ids.join(',')
      }
    })
  }
  goBack(): void {
    window.history.go(-1)
  }

  render(): JSX.Element {
    return <div class="shop_card">
      {this.clientWidth <= 900 && <div class="shop_card-back" onClick={this.goBack}>返回</div>}
      <div class="shop_card-header">我的购物车</div>
      {this.cardTable()}
      {this.setDom()}
    </div>
  }

  mounted(): void {
    this.getList()
    this.clientWidth = document.body.clientWidth
    window.onresize = () => {
      this.clientWidth = document.body.clientWidth
    }
  }
}
